/*
    Basic idea:
    <qnc-filter-result form="some_form_id" data-set-name="filter-result">
    Uses filter_form.js to attach a listener for given data set name.
    Response of that request will be used to set the innerHTML of the element.
    
    Reminder: this will trigger XHR POST requests to form's action with:
        - action="GET_DATASET"
        - data-set-name=[as specified via data-set-name attribute]
    You must create a handler to process those requests and return text/html.

    You can have multiple <qnc-filter-result> elements for the same form, as long as they have different data-set-names.
*/

import FilterForm from './filter_form.js'
class FilterResult extends HTMLElement {
    constructor() {
        super()
        const form_id = this.getAttribute('form')
        if (!form_id) throw new Error(`${this.tagName} must set "form" attribute`)
        const form = document.getElementById(form_id)
        if (!form || !(form instanceof HTMLFormElement)) throw new Error(`"${form_id}" does not identify a form element by id`)

        const data_set_name = this.getAttribute('data-set-name')
        if (!data_set_name) throw new Error(`${this.tagName} must set "data-set-name" attribute`)

        new FilterForm(form).add_data_set(
            data_set_name,
            data => {
                if (data.state == 'FETCHING') {
                    // TODO: better loading indicator?
                    // TODO: only show loading indicator if response doesn't return within 250ms, show for minimum of 1s? (just to avoid ugly quick flash-in/out of loading indicator)
                    // Maybe if loading indicator just adds an overlay over top of content area (without replacing content, so height doesn't change) it would be better
                    this.innerText = 'loading...'
                    return
                }
                if (data.state == 'FAILURE') {
                    this.innerText = 'failed to fetch data summary'
                    return
                }
                this.innerHTML = data.value || ''
            }
        )
    }
}
customElements.define('qnc-filter-result', FilterResult)