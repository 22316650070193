/*
    <disappearing-container disappears-at=UNIX_TIME_MILLISECONDS>X</disappearing-container>

    will render X followed by a count down "(3s)"
    when the countdown gets to 0, the element renders nothing at all

    you can style the countdown text using
    disappearing-container::part(countdown) {
        ...
    }
*/

class DisappearingContainer extends HTMLElement {
    #wrapper: HTMLElement
    #output: HTMLElement
    constructor() {
        super()
        const shadow = this.attachShadow({mode: 'open'})
        shadow.innerHTML = `<span id=wrapper><slot></slot><span part=countdown>(<output></output>s)</span></span>`
        this.#output = shadow.querySelector('output') as HTMLElement
        this.#wrapper = shadow.querySelector('#wrapper') as HTMLElement
        this.setTime = this.setTime.bind(this)
        this.setTime()
    }

    setTime() {
        const attr = this.getAttribute('disappears-at')
        const disappear_milliseconds = attr ?
            parseFloat(attr) : new Date().getTime()
        const seconds_left = (disappear_milliseconds - (new Date()).getTime())/1000
        const rounded_seconds_left = Math.round(seconds_left)

        if (rounded_seconds_left <= 0) {
            this.#wrapper.style.display = 'none'
            return
        }
        this.#wrapper.style.display = ''

        this.#output.innerText = rounded_seconds_left.toString()

        const fraction_seconds = seconds_left - Math.floor(seconds_left)
        setTimeout(
            this.setTime, 
            (fraction_seconds ? fraction_seconds : 1) * 1000,
        );
    }

    static observedAttributes = ["disappears-at"];
    attributeChangedCallback(name: string, old_value: string|null, new_value:string|null) {
        this.setTime()
    }
}
customElements.define('disappearing-container', DisappearingContainer)