/*
    Conceptually, a "filter form" is a <form> which posts its data to retrieve one or more "data sets" via xhr. One request per data-set. Data-set state is passed to registered callbacks.


    Idea:
    new FilterForm(some_form_element).add_data_set(
        'filter-summary',
        function({value, state}) {
            ...
        }
    )

    Constructing the FilterForm causes the form to:
    - prevent default submit event handling
    - submit (in a debounced/intelligent way) automatically on any input change

    Calling add_data_set:
    - triggers a new POST XMLHttpRequest to be sent on every form submit
    - the request will be sent to form's action
    - the request payload will have the following parameters added:
        - action='GET_DATASET'
        - data-set-name=[as given in call to add_data_set()]
    - the content of response will be passed to handler via "value" property
        - if you return text/plain or text/html, this will be a string
        - if you return text/json, this will be parsed automatically
*/
type DATA_SET_STATE = {
    readonly value: any, // initial value is always null; after we've received a response, the type of value depends on type of response
    readonly state: 'FETCHING' | 'SUCCESS' | 'FAILURE'
}
type DATA_SET_LISTENER = (data: Readonly<DATA_SET_STATE>) => void
type FORM_STATE = {
    dirty: boolean,
    fetching: boolean,
}

class DataSet {
    private _state: DATA_SET_STATE = {value: null, state: 'SUCCESS'}
    private listeners: Array<DATA_SET_LISTENER> = []
    private xhr: XMLHttpRequest

    get state(): Readonly<DATA_SET_STATE> {
        return {...this._state}
    }

    constructor(
        private name: string,
    ) {
        const xhr = new XMLHttpRequest()

        const on_error = () => {
            this._state = {value: null, state: 'FAILURE'}
            this.call_listeners()
        }

        xhr.onerror = on_error
        xhr.onload = () => {
            const status = xhr.status
            if (status < 200 || status > 299) {
                console.error(`${this.name} response status: ${status}`)
                on_error()
                return
            }

            this._state = {value: xhr.response, state: 'SUCCESS'}
            this.call_listeners()
        }
        this.xhr = xhr
    }
    add_listener(listener: DATA_SET_LISTENER) {
        this.listeners.push(listener)
        setTimeout(() => {
            listener({...this._state})
        }, 0)
    }
    fetch_data(form: HTMLFormElement) {
        const data = new FormData(form)
        data.append('action', 'GET_DATASET')
        data.append('data-set-name', this.name)

        this.xhr.abort()
        this.xhr.open('POST', form.action)
        this.xhr.send(data)

        this._state = {
            state: 'FETCHING',
            // leave most recent value untouched
            // users may want to leave it displayed while fetching, and this makes it easier for them to do that
            // users MAY decide to clear display as soon as they see FETCHING; that's their choice
            value: this._state.value,
        }

        this.call_listeners()
    }
    call_listeners() {
        for (const listener of this.listeners) {
            try {
                listener({...this._state})
            } catch (e) {
                console.error(e)
            }
        }
    }
}

type Watchable<T> = {
    readonly value: T
    onchange(listener: (value: T) => void): void
}
class SettableWatchable<T> implements Watchable<T> {
    private _value: T
    private listeners: Array<(value: T) => void> = []
    get value(): T {return this._value}
    constructor(initial: T) {
        this._value = initial
    }
    onchange(listener: (value: T) => void): void {
        this.listeners.push(listener)
    }
    set_value(value: T): void {
        if (value == this._value) return
        this._value = value
        for (const listener of this.listeners) {
            try {
                listener(value)
            } catch(e) {
                console.error(e)
            }
        }
    }
}

function on_any_form_change(form: HTMLFormElement, callback: () => void) {
    // listens to change events, and clicks on radio/checkbox inputs
    form.addEventListener('change', callback)
    const w = form.ownerDocument.defaultView
    if (!w) throw new Error('form must be attached to a window')
    w.addEventListener('click', function(e) {
        const t = e.target;
        if (
            t 
            && t instanceof w.HTMLInputElement 
            && (t.type == 'checkbox' || t.type == 'radio')
            && t.form == form
        ) {
            callback()
        }
    })
}

const filter_forms = new WeakMap<HTMLFormElement, FilterForm>()

/*

*/
class FilterForm extends EventTarget {
    private data_sets: Map<string, DataSet> = new Map()
    private started_submission_timer: boolean = false
    private _is_dirty = new SettableWatchable(false)
    private _is_fetching = new SettableWatchable(false)

    get is_dirty(): Watchable<boolean> {return this._is_dirty}
    get is_fetching(): Watchable<boolean> {return this._is_fetching}

    constructor(
        readonly form: HTMLFormElement
    ) {
        // Only one FilterForm can exist per form element. Trying to create a new one returns the existing one.
        const existing_filter_form = filter_forms.get(form)
        if (existing_filter_form) return existing_filter_form

        super()
        filter_forms.set(form, this)


        form.addEventListener('submit', event => {
            event.preventDefault()
            this.submit()
        })

        on_any_form_change(form, () => {
            this._is_dirty.set_value(true)
        })

        /*
            Note: we were initially triggering submit (debounced) on "input" events.

            Now, we're only listening to "change" events, so the debouncing/throttling isn't really useful.

            In the future, I'd like to provide options to support either behaviour (actually, we should remove this feature from FilterForm, and place in separate function). However, we should NOT make "submit on input" the default. Some filter forms trigger expensive backend calculations, and should NOT submit-as-you-type. That should be opt-in behaviour.
        */
        on_any_form_change(form, () => {
            if (this.started_submission_timer) return
            this.started_submission_timer = true
            setTimeout(() => {
                this.started_submission_timer = false
                this.submit()
            }, 100)
        })
    }


    submit() {
        for (const data_set of this.data_sets.values()) {
            data_set.fetch_data(this.form)
        }
        this._is_dirty.set_value(false)
        
    }

    /*
        Causes the FilterForm to make requests for data set with given name, and call given callback any time that data changes
        Callback will initially be called after a timeout, with the current state of that data set
    */
    add_data_set(
        data_set_name: string, 
        listener: (data: DATA_SET_STATE) => void
    ) {
        const data_set = this.data_sets.get(data_set_name) || this.build_data_set(data_set_name)
        if (!this.data_sets.has(data_set_name)) {
            this.data_sets.set(data_set_name, data_set)
        }
        data_set.add_listener(listener)
    }
    private build_data_set(name: string): DataSet {
        const data_set = new DataSet(name)
        data_set.add_listener(() => this.determine_fetching_state())

        data_set.fetch_data(this.form)
        return data_set
    }
    private determine_fetching_state() {
        this._is_fetching.set_value(
            Array.from(this.data_sets.values()).some(data_set => data_set.state.state == 'FETCHING'),
        )
    }
}
export default FilterForm;

// just for testing
(window as any)._DEV_FilterForm = FilterForm
